const setRenewalDate = (values) => {
  const { registration_detail } = values;
  let renewal_date = new Date();
  if (registration_detail) {
    if (values?.renewal_period === "yearly") {
      if (Number(values?.renewal_month) > renewal_date?.getMonth() + 1) {
        renewal_date?.setDate(values?.renewal_day);
        renewal_date?.setMonth(values?.renewal_month - 1);
        renewal_date?.setFullYear(renewal_date?.getFullYear());
      } else if (Number(values?.renewal_month) === renewal_date?.getMonth() + 1) {
        if (Number(values?.renewal_day) > renewal_date?.getDate()) {
          renewal_date?.setDate(values?.renewal_day);
          renewal_date?.setMonth(values?.renewal_month - 1);
          renewal_date?.setFullYear(renewal_date?.getFullYear());
        } else {
          renewal_date?.setDate(values?.renewal_day);
          renewal_date?.setMonth(values?.renewal_month - 1);
          renewal_date?.setFullYear(renewal_date?.getFullYear() + 1);
        }
      } else {
        renewal_date?.setDate(values?.renewal_day);
        renewal_date?.setMonth(values?.renewal_month - 1);
        renewal_date?.setFullYear(renewal_date?.getFullYear() + 1);
      }
    } else {
      if (Number(values?.renewal_day) > renewal_date?.getDate()) {
        renewal_date?.setDate(values?.renewal_day);
        renewal_date?.setMonth(renewal_date?.getMonth());
      } else {
        renewal_date?.setDate(values?.renewal_day);
        renewal_date?.setMonth(renewal_date?.getMonth() + 1);
      }
    }
    return { ...values, renewal_date : renewal_date?.toISOString() };
  } else {
    return values;
  }
};


const setRenewalDateEdit = (values) => {
  const { registration_detail } = values;
  let renewal_date = new Date(values?.renewal_date);
  if(!values.renewal_date || values.renewal_date == "" || values.renewal_date == null                                                                     ){
    renewal_date = new Date();
  }
  if (registration_detail) {
    if (values?.renewal_period === "yearly") {
      if (Number(values?.renewal_month) > renewal_date?.getMonth() + 1) {
        renewal_date?.setDate(values?.renewal_day);
        renewal_date?.setMonth(values?.renewal_month - 1);
        renewal_date?.setFullYear(renewal_date?.getFullYear());
      } else if (Number(values?.renewal_month) === renewal_date?.getMonth() + 1) {
        if (Number(values?.renewal_day) > renewal_date?.getDate()) {
          renewal_date?.setDate(values?.renewal_day);
          renewal_date?.setMonth(values?.renewal_month - 1);
          renewal_date?.setFullYear(renewal_date?.getFullYear());
        } else {
          renewal_date?.setDate(values?.renewal_day);
          renewal_date?.setMonth(values?.renewal_month - 1);
          if(!values.renewal_date){
            renewal_date?.setFullYear(renewal_date?.getFullYear()+1);
          }else{
            renewal_date?.setFullYear(renewal_date?.getFullYear());
          }
        }
      } else {
        renewal_date?.setDate(values?.renewal_day);
        renewal_date?.setMonth(values?.renewal_month - 1);
        if(!values.renewal_date){
          renewal_date?.setFullYear(renewal_date?.getFullYear()+1);
        }else{
          renewal_date?.setFullYear(renewal_date?.getFullYear());
        }
      }
    } else {
      if (Number(values?.renewal_day) > renewal_date?.getDate()) {
        renewal_date?.setDate(values?.renewal_day);
        if(!values.renewal_date){
          renewal_date?.setMonth(renewal_date?.getMonth() + 1);
        }else{
          renewal_date?.setMonth(renewal_date?.getMonth());
        }
      } else {
        renewal_date?.setDate(values?.renewal_day);
        if(!values.renewal_date){
        renewal_date?.setMonth(renewal_date?.getMonth() + 1);
        }else{
          renewal_date?.setMonth(renewal_date?.getMonth());
        }
      }
    }
    return { ...values, renewal_date : renewal_date.toISOString()};
  } else {
    return values;
  }
};

export const registrationDetails = (values) => {
  if (values?.child?.length) {
    values.child = values?.child?.map((element) => {
      element.child_name = typeof element?.child_name === "string" ? element.child_name.trim() : element?.child_name;
      element.renewal_notes = typeof element?.renewal_notes === "string" ? element.renewal_notes.trim() : element?.renewal_notes;
      element = values?.id ? setRenewalDateEdit(element) : setRenewalDate(element);
      return element;
    });
    values = { ...values, child_exists: true };
  } else {
    values = values?.id ? setRenewalDateEdit(values) : setRenewalDate(values);
    values = { ...values, child_exists: false };
  }

  Object.keys(values)?.forEach((k) => {
    if (typeof values[k] === "string") {
      values[k] = values[k].trim().replace(/  +/g, " ");
    }
  });

  return values;
};

