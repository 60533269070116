import React, { useEffect, useState } from "react";
import slider1 from "../../assets/images/education-school-slider.png";
import slider2 from "../../assets/images/reading-slider.png";
import slider3 from "../../assets/images/slider-img.png";
import shape1 from "../../assets/images/shape-1.png";
import shape2 from "../../assets/images/shape-2.png";
import Carousel from "react-bootstrap/Carousel";
import { useNavigate } from "react-router-dom";
import SchoolAdmin from "../../services/admin.service";
import { useDispatch, useSelector } from "react-redux";
import { setWelcomeScreen } from "../../features/authSlice";

const OnBoarding = () => {
  const { token,adminInfo } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => setIndex(selectedIndex);

console.log('shhshshh')

  const handleNavigation = () => {
    SchoolAdmin.welcomeScreen()
      .then(() => {
        
      

        if(adminInfo && (!adminInfo.acronym || !adminInfo.acronym.trim())){
        
          let user = { token, welcomeScreen: false };
          dispatch(setWelcomeScreen(false));
          localStorage.setItem("user", JSON.stringify(user));
          navigate('/acronym', { replace: true });
        }

        else if(adminInfo && adminInfo?.acronym){
        
          let user = { token, welcomeScreen: true };
          dispatch(setWelcomeScreen(true));
          localStorage.setItem("user", JSON.stringify(user));
          navigate("/home", { replace: true });
        }

        else{
          
          let user = { token, welcomeScreen: true };
          dispatch(setWelcomeScreen(true));
          localStorage.setItem("user", JSON.stringify(user));
          navigate("/home", { replace: true });
        }
        
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    console.log("`11111111111111");
    if(!token){
      navigate("/login")
    }
  },[])

  return (
    <>
      <div className="wraper-inner bg-grey space-top ">
        <section className="onboarding d-flex align-items-center justify-content-center min-box">
          <span className="shape-1">
            <img src={shape1} alt="shape" />
          </span>
          <span className="shape-2">
            <img src={shape2} alt="shape" />
          </span>
          <div className="d-flex flex-column" style={{ minHeight: "436px" }}>
            <div className="container">
              <Carousel activeIndex={index} onSelect={handleSelect} interval={null} className="slider-box">
                <Carousel.Item>
                  <div className="slider-content-box d-flex align-items-center">
                    <figcaption>
                      <h3>Foster engaging relationships</h3>
                      <p>Build lasting relationships with your customers. Discover the magic of streamlined communications and watch your business thrive.</p>
                    </figcaption>

                    <figure>
                      <img className="d-block w-100" src={slider1} alt="First slide" />
                    </figure>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="slider-content-box d-flex align-items-center">
                    <figcaption>
                      <h3>Use it anywhere for real-time notifications</h3>
                      <p>Your customers stay in the know, no matter where they go! Experience the freedom of instant updates - anytime, anywhere.</p>
                    </figcaption>

                    <figure>
                      <img className="d-block w-100" src={slider2} alt="First slide" />
                    </figure>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="slider-content-box d-flex align-items-center">
                    <figcaption>
                      <h3>No subscription fees</h3>
                      <p>No contracts. No hidden fees. Ever. Say goodbye to subscription fees and hello to a small fee per payment transaction.</p>
                    </figcaption>

                    <figure>
                      <img className="d-block w-100" src={slider3} alt="First slide" />
                    </figure>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
            <div className="d-flex align-items-center justify-content-center btn-slider">
              {index === 2 && (
                <button className="btn big-btn hvr-sweep-to-right mt-4" onClick={handleNavigation}>
                  Continue
                </button>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default OnBoarding;
