import { Link, useNavigate , useParams } from "react-router-dom";
import SchoolAdmin from "../../services/admin.service";
import { MESSAGE } from "../../utils/ValidationMessage";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { Formik } from "formik";
import shape1 from "../../assets/images/shape-1.png";
import shape2 from "../../assets/images/shape-2.png";
import logoform from "../../assets/images/logo-form.png";
import { NOSPACE_REGEX } from "../../utils/Constants";
import { Error, Note, Success } from "../../utils/Alert";
import { setWelcomeScreen } from "../../features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import AuthService from "../../services/auth.service";

// Utility function to remove HTML tags
const removeHtmlTags = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
};

const Acronym = () => {
  const { token, adminInfo } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token_id } = useParams();
  const validationSchema = Yup.object().shape({
    acronym: Yup.string().strict(true).max(50, "Institute Acronym should be less than 50 chars").required(MESSAGE.REQUIRED).matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),
  });
  
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    AuthService.getAcronymCms({})
      .then((data) => {
        setTitle(data?.data?.data?.title);
        // Clean the description from HTML tags
        setDescription(removeHtmlTags(data?.data?.data?.description));
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const onSubmit = (values, { setSubmitting }) => {
    Object.keys(values)?.forEach((k) => (typeof values[k] === "string" ? (values[k] = values[k]?.trim().replace(/  +/g, " ")) : values[k]));
    SchoolAdmin.setUpAchronym(values)
      .then((res) => {
        const { addedby } = res?.data;
        Swal.fire({ text: "Acronym added successfully", ...Success });
        let user = { token, welcomeScreen: true };
        localStorage.setItem("user", JSON.stringify(user));
        dispatch(setWelcomeScreen(true));
        navigate("/home", { replace: true });
      })
      .catch((err) => {
        const { message } = err?.response?.data;
        Swal.fire({ text: message ? message : Note?.Err, ...Error });
        setSubmitting(false);
      });
  };

  useEffect(()=>{
    console.log("hello");
  },[])

  return (
    <>
      <div className="wraper-inner bg-box space-top">
        <section className="reset-password d-flex justify-content-center align-items-center">
          <span className="shape-1">
            <img src={shape1} alt="logo" />
          </span>
          <span className="shape-2">
            <img src={shape2} alt="logo" />
          </span>
          <div className="container">
            <div className="row">
              <div className="col-md-4 m-auto">
                <div className="form-head text-center">
                  <figure>
                    <img src={logoform} alt="logo" />
                  </figure>
                  <h5 className="form-title">Set Up Institution Acronym</h5>
                </div>
                <Formik initialValues={{ acronym: "" }} validationSchema={validationSchema} onSubmit={onSubmit}>
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="text-center">
                        <label className="custom-field one">
                          <input type="text" name="acronym" placeholder=" " onChange={handleChange} onBlur={handleBlur} value={values?.acronym} />
                          <span className="placeholder">Institute Acronym*</span>
                          <i className="ti ti-building"></i>
                          <span className="text-danger">{touched.acronym && errors.acronym}</span>
                        </label>
                        <Link to="#" className="d-inline-block mt-3 mb-5 blue-link justify-content-center" data-bs-toggle="modal" data-bs-target="#setup-acronym">
                          What's this?
                        </Link>
                      </div>
                      <div>
                        <button type="submit" className="btn full-btn hvr-sweep-to-right" disabled={isSubmitting}>
                          Continue
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <!-- setup-acronym --> */}
      <div className="modal fade common-modal" id="setup-acronym" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-head text-center mb-4">
              <h5 className="modal-title" id="exampleModalLabel">
                {title}
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <i className="ti ti-x"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center content-modal">
                <p>{description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Acronym;
