import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Formik, useFormik } from "formik";
import SchoolAdmin from "../../../services/admin.service";
import { MESSAGE } from "../../../utils/ValidationMessage";
import { MOBILE_REGEX, NOSPACE } from "../../../utils/Constants";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { Error, Note } from "../../../utils/Alert";
import { useSelector } from "react-redux";

const AddClassParent = ({ refresh, setRefresh }) => {
  const [show, setShow] = useState(true);
  const { adminInfo } = useSelector((state) => state?.auth);
  const [data, setData] = useState([]);

  useEffect(() => {
    SchoolAdmin.viewGroupDropDown({ allInstitutesIds: [adminInfo?.instituteid] })
      .then((res) => {
        let arr = res?.data?.data?.map((element) => ({ ...element, isChecked: false }));
        setData(arr);
      })
      .catch((err) => console.log(err));
  }, []);

  const cxhandleChange = (e) => {
    const { id, checked, name } = e?.target;
    let temp;
    if (name === "allSelect") {
      temp = data?.map((user) => {
        return { ...user, isChecked: checked };
      });
      setData(temp);
    } else {
      temp = data?.map((user) => (user?.group_id == id ? { ...user, isChecked: checked } : user));
      setData(temp);
    }
  };

  const formik = useFormik({
    // enableReinitialize : true,
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      phoneno: "",
      group_id: [],
      role: "parent",
      notification: true,
      child_exists: false,
    },
    validationSchema: Yup.object().shape({
      firstname: Yup.string().strict(true).trim(NOSPACE).max(30, "First name should be less than 30 chars").required(MESSAGE.REQUIRED),
      lastname: Yup.string().max(30, "Last name should be less than 30 chars").required(MESSAGE.REQUIRED).trim(NOSPACE),
      email: Yup.string().email(MESSAGE.EMAIL).max(255).required(MESSAGE.REQUIRED),
      phoneno: Yup.string().matches(MOBILE_REGEX, MESSAGE.PHONE).required(MESSAGE.REQUIRED),
      group_id: Yup.array().required().min(1, "Must be a member of atleast one group"),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      console.log(values);
      SchoolAdmin.addMember(values)
        .then((res) => {
          Swal.fire({ text: Note.ParentCreated, icon: "success" });
          let arr = data?.map((element) => ({ ...element, isChecked: false }));
          setData(arr);
          resetForm();
          setRefresh(!refresh);
        })
        .catch((err) => {
          const { message } = err?.response?.data;
          if (message) {
            Swal.fire({ text: message, ...Error });
          } else {
            Swal.fire({ text: Note.Err, ...Error });
          }
          console.log(err);
          resetForm();
          setSubmitting(false);
        });
    },
  });

  return (
    <>
      <div className="modal fade common-modal" id="addnew-class-parent" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-head text-center">
              {show ? (
                <>
                  <h5 className="modal-title" id="exampleModalLabel">
                    Add Class Parent
                  </h5>

                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                    <i className="ti ti-x"></i>
                  </button>
                </>
              ) : (
                <>
                  <h5 className="modal-title" id="exampleModalLabel">
                    Select Groups
                  </h5>
                  <button type="button" className="btn-close" onClick={() => setShow(true)}>
                    <i className="ti ti-x"></i>
                  </button>
                </>
              )}
            </div>

            <div className="modal-body">
              <form onSubmit={formik.handleSubmit}>
                {show ? (
                  <>
                    <label className="custom-field one mb-3 without-icon">
                      <input name="firstname" type="text" placeholder=" " onChange={formik.handleChange} onBlur={formik.handleChange} value={formik?.values?.firstname} />
                      <span className="placeholder">First Name</span>
                      <p className="text-danger">{formik?.errors?.firstname && formik?.touched?.firstname && formik?.errors?.firstname}</p>
                    </label>
                    <label className="custom-field one mb-3 without-icon">
                      <input name="lastname" type="text" placeholder=" " onChange={formik.handleChange} onBlur={formik.handleChange} value={formik?.values?.lastname} />
                      <span className="placeholder">Last Name</span>
                      <p className="text-danger">{formik?.errors?.lastname && formik?.touched?.lastname && formik?.errors?.lastname}</p>
                    </label>
                    <label className="custom-field one mb-3 without-icon">
                      <input name="email" type="text" placeholder=" " onChange={formik.handleChange} onBlur={formik.handleChange} value={formik?.values?.email} />
                      <span className="placeholder">Email</span>
                      <p className="text-danger">{formik?.errors?.email && formik?.touched?.email && formik?.errors?.email}</p>
                    </label>
                    <label className="custom-field one mb-3 without-icon">
                      <input name="phoneno" type="text" placeholder=" " onChange={formik.handleChange} onBlur={formik.handleChange} value={formik?.values?.phoneno} />
                      <span className="placeholder">Phone Number</span>
                      <p className="text-danger">{formik?.errors?.phoneno && formik?.touched?.phoneno && formik?.errors?.phoneno}</p>
                    </label>
                    <div className="mb-3 ">
                      <a className="select-group d-flex justify-content-between" onClick={() => setShow(false)}>
                        Select Groups
                        <div className="d-flex align-items-center ">
                          <i className="ti ti-chevron-right"></i>
                        </div>
                      </a>
                      <span className="custom-field text-danger">{formik?.submitCount !== 0 && formik?.errors?.group_id}</span>
                      {data?.length > 0 ? (
                        <div className="tags-input-wrapper" style={{ border: "none" }}>
                          {data?.map((group, index) =>
                            group?.isChecked ? (
                              <span className="tag" key={index}>
                                {group?.group_name}
                              </span>
                            ) : null
                          )}
                        </div>
                      ) : null}
                    </div>
                    <div className="btn-box mt-4">
                      <button type="submit" className="btn full-btn hvr-sweep-to-right">
                        Add
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mb-4">
                      <label className="coustom-checkbox s-check-box ">
                        <input type="checkbox" />
                        <span className="checkmark"></span>
                        <p className="select-all-blue">Select All</p>
                      </label>
                      <div className="select-group-box">
                        {data?.map((group, i) => (
                          <label className="coustom-checkbox s-check-box select-group" key={i}>
                            <input type="checkbox" name="group_id" id={group?.group_id} checked={group?.isChecked || false} onChange={cxhandleChange} />
                            <span className="checkmark"></span>
                            <p>{group?.group_name}</p>
                          </label>
                        ))}
                      </div>
                    </div>
                    <div className="btn-box">
                      <button
                        type="button"
                        className="btn full-btn hvr-sweep-to-right"
                        onClick={() => {
                          let arr = data
                            ?.map((x) => {
                              if (x?.isChecked) return x?.group_id;
                            })
                            ?.filter(Boolean);

                          formik.setFieldValue("group_id", arr);
                          setShow(true);
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddClassParent;
