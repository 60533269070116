import { ErrorMessage, Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { MESSAGE } from "../../utils/ValidationMessage";
import { NOSPACE_REGEX, SUPPORTED_FORMATS, supportedVideoFormats } from "../../utils/Constants";
import { TagsInput } from "react-tag-input-component";
import { beforeAddValidate } from "../../utils/ValidateTag";
import Report from "./Report";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AWS_BUCKET_NAME } from "../../utils/Constants";
import SchoolAdmin from "../../services/admin.service";
import Swal from "sweetalert2";
import { Error, Info, Note, Success } from "../../utils/Alert";
import { TextCharacterLimitShow, TrimehandleChange, handleBlurTrim, handleFileChange } from "../../utils/FileSize";
import { useEffect, useState } from "react";
import Finance from "../../services/finance.service";

const validationSchema = Yup.object().shape({
  zip_code: Yup.array()
    .min(1, "At least one zip code is required")
    .test("isValidZipCodes", "Invalid zip code", (zipCodes) => {
      if (!zipCodes || zipCodes?.length === 0) {
        // If the array is empty, we consider it valid because the min(1) rule will handle it.
        return true;
      }
      for (let i = 0; i < zipCodes?.length; i++) {
        const zipCode = zipCodes[i];
        // Check if the zip code is a 5-digit number
        if (!/^\d{5}$/.test(zipCode)) {
          return false; // Validation failed
        }
      }
      return true; // All zip codes are valid
    }),
  title: Yup.string().label("title").strict(true).max(100, MESSAGE.TITLE).required(MESSAGE.REQUIRED),
  description: 
    Yup.string().min(2, 'Full name must be atleast 2 character')
    .max(500, 'Description must not be more than 500 characters.')
    .required(MESSAGE.REQUIRED),
  fileValidation: Yup.mixed()
    .test("fileFormat", "Unsupported Format", (value) => {
      if (typeof value === "string") {
        return true;
      } else {
        return !value || (value && SUPPORTED_FORMATS.includes(value?.type));
      }
    }),

    thumbnailValidation: Yup.mixed().when(['fileuploaded[0].mimetype', 'fileuploaded[0].thumbnail'], {
      is: (mimetype, thumbnail) => {
        return mimetype && mimetype.startsWith('video/');
      },
      then: Yup.mixed()
        .required('Please upload a thumbnail ')
        .test("fileSize", "Thumbnail too large. Max size is 1MB.", (value) => {
          if (typeof value === "string" || typeof value === "undefined") {
            return true;
          } else {
            return value && value.size <= 1000000;
          }
        }),
      otherwise: Yup.mixed().notRequired(),
    }),
});

const CampaignManager = () => {
  const { adminInfo, profile } = useSelector((state) => state?.auth);
  const navigate = useNavigate();
  const [errorMsg, setErrormsg] = useState('');
  const [data, setData] = useState({});

  useEffect(() => {
    Finance.viewSettings().then((res) => setData(res?.data?.data));
  }, []);


  const upload_thumbnail_on_S3 = async (file, setFieldValue, setSubmitting) => {
    setSubmitting(true);
    setFieldValue("thumbnailValidation", file)

    let url = '';
    let unique_filename = '';
    await SchoolAdmin.generatePresignedUrl({
      "filename": file?.name,
      "contentType": file?.type
    })
      .then((res) => {
        url = res?.data?.data?.url;
        unique_filename = res?.data?.data?.filename;
      })
      .catch((err) => {
        const { message } = err?.response?.data;
        Swal.fire({ text: message ? message : Note?.Err, ...Error });
      });

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': file?.type
      },
      body: file
    });
    if (response?.ok) {
      let path = `https://${AWS_BUCKET_NAME}.s3.amazonaws.com/`;
      setFieldValue("fileuploaded[0].thumbnail", path + unique_filename);
      setSubmitting(false);
      console.log('Image uploaded successfully!');
    } else {
      console.error('Failed to upload image', response?.statusText);
    }
  };

  const initialValues = {
    zip_code: [],
    title: "",
    description: "",
    fileuploaded: [],
    fileValidation: "",
    thumbnailValidation: "",
    event_type: "sponsered",
    user_name: profile?.fullname,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    // onSubmit: (values) => {
    //   navigate("/payment", { state: { details: { ...values, ...adminInfo } } });
    // },
    onSubmit: async(values,{ resetForm }) => {
      try {
        let res;
        if(data?.ad_post_fee == 0){
  let payment_key = false;
          res = await Finance.addCampaign( {...values, ...adminInfo, payment_key} );
          Swal.fire({ text: res?.data?.message, ...Success });
        } else {
          navigate("/payment", { state: { details: { ...values, ...adminInfo } } });
        }
        
        // navigate(-1);
        resetForm();
      } catch (error) {
        console.log("err",error);
        // const { message } = error?.response?.data;
        // Swal.fire({ text: message ? message : Note?.Err, ...Error });
      
      }
      
    },
  });

  const { values, handleBlur, setFieldValue, handleChange, isSubmitting, setSubmitting, errors } = formik;


  

  return (
    <>
      <div className="wraper-inner bg-grey space-top">
        <section className="create-new-post pt-4 pb-5 min-box">
          <div className="container">
            <div className="similar-shadow-box pb-5">
              <h3 className="inner-title mb-4">Campaign Manager </h3>
              <ul className="nav nav-tabs similer-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="ad-request-tab" data-bs-toggle="tab" data-bs-target="#ad-request" type="button" role="tab" aria-controls="home" aria-selected="true">
                    Ad Request
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="campaign-report-tab" data-bs-toggle="tab" data-bs-target="#campaign-report" type="button" role="tab" aria-controls="profile" aria-selected="false">
                    Campaign Report
                  </button>
                </li>
              </ul>
              <div className="tab-content mt-4" id="myTabContent">
                <div className="tab-pane fade show active" id="ad-request" role="tabpanel" aria-labelledby="ad-request-tab">
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>

                      <div className="mb-4 ">
                        <TagsInput
                          name="zip_code"
                          value={values?.zip_code}
                          onChange={(values) => setFieldValue("zip_code", values)}
                          beforeAddValidate={beforeAddValidate}
                          separators={[","]}
                          placeHolder={values?.zip_code?.length ? "" : "Target Zip Code (use comma to enter multiple zip codes)"}
                        />
                        <ErrorMessage component="p" className="text-danger" name="zip_code" />
                      </div>
                      <div className="mb-4">
                        <label className="custom-field one without-icon">
                          <input 
                            type="text" 
                            placeholder=" " 
                            name="title" 
                            onChange={TrimehandleChange (setFieldValue)('title')} 
                            onBlur={handleBlurTrim(handleChange)} 
                            value={values?.title} />
                          <span className="placeholder">Post Title</span>
                          <span className="text-danger">
                            <ErrorMessage name="title" />
                          </span>
                        </label>
                      </div>
                      <div className="mb-4 textarea-box">
                        <label className="custom-field one without-icon">
                            <textarea 
                              className="label-textarea" 
                              name="description" 
                              placeholder="Description" 
                              onChange={handleChange} 
                              // onBlur={handleBlurTrim(handleChange)} 
                              value={values.description}>
                            </textarea>
                          <span className="text-danger">
                            <ErrorMessage name="description" />
                          </span>
                        </label>
                      </div>
                      <div className="mb-4">
                        <div className="file-upload-wrapper" data-text="Add Attachment">
                          <input
                            name="fileValidation"
                            type="file"
                            className="file-upload-field"
                            onChange={(e) => handleFileChange(e, setFieldValue, setSubmitting, setErrormsg)}
                            onClick={(e) => {
                              e.target.value = null;
                            }}
                          />
                          <button>
                            <i className="ti ti-paperclip"></i>
                          </button>
                        </div>
                        <div className="custom-field text-danger">
                          {errorMsg}
                        </div>
                        {values?.fileValidation && (
                          <div className="text-success d-flex align-item-center justify-content-between p-1" style={{ fontSize: "14px" }}>
                            {TextCharacterLimitShow(values?.fileValidation?.name,30) + " file selected"}
                            <div className="delete-post ms-2" onClick={() => { setFieldValue("thumbnailValidation", "");setFieldValue("fileuploaded[0].mimetype",""); setFieldValue("fileuploaded[0].thumbnail",""); setFieldValue("fileValidation", ""); setFieldValue("fileuploaded[0].file", "") }}>
                              <i className="ti ti-trash"></i>
                            </div>
                          </div>
                        )}

                        {(supportedVideoFormats.includes(values?.fileuploaded[0]?.mimetype)) && (
                          <>
                            <div className="file-upload-wrapper" data-text="Add Thumbnail">
                              <input
                                name="thumbnailValidation"
                                type="file"
                                accept="image/*"
                                className="file-upload-field"
                                onChange={(e) => {
                                  (e?.target?.files[0]?.type?.startsWith('image/')) ?
                                  upload_thumbnail_on_S3(e.target?.files[0], setFieldValue, setSubmitting) 
                                  :   Swal.fire({ text: "Please upload an image" || Note.Err, ...Error });
                                  }}
                                onClick={(e) => {
                                  e.target.value = null;
                                }}
                              />
                              <button>
                                <i className="ti ti-paperclip"></i>
                              </button>
                            </div>

                            <ErrorMessage component="p" className="custom-field text-danger" name="thumbnailValidation" />

                            {values?.thumbnailValidation && (
                              <div className="text-success d-flex align-item-center justify-content-between p-1" style={{ fontSize: "14px" }}>
                                {TextCharacterLimitShow(values?.fileuploaded[0]?.thumbnail,30) + " file selected"}
                                <div className="delete-post ms-2" onClick={() => { setFieldValue("thumbnailValidation", ""); setFieldValue("fileuploaded[0].thumbnail", "") }}>
                                  <i className="ti ti-trash"></i>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>

                      <div className="mt-4 btn-box d-flex justify-content-center align-items-center">
                        <button type="submit" disabled={isSubmitting} className="btn big-btn hvr-sweep-to-right">
                        {data?.ad_post_fee == 0 ? "Add" : "Next"}  
                        </button>
                      </div>
                    </Form>
                  </FormikProvider>
                </div>
                <div className="tab-pane fade" id="campaign-report" role="tabpanel" aria-labelledby="campaign-report-tab">
                  <Report />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CampaignManager;